import React from 'react';
import Heading from '../../molecules/Heading/Heading';
import Section from '../../molecules/Section/Section';
import { colors } from '../../styles/atoms/colors';
import { customInnerStyle } from './HeadingComponent.style';

const HeadingComponent = ({
  component: { id, background, hasHexes, headingBlock, removeBottomPadding },
  includeHeroPadding,
}) => {
  if (!headingBlock) return null;
  const { kicker, heading, subhead, alignment, buttons, content } = headingBlock[0];
  return (
    <Section
      id={id}
      bgColor={background || colors.base.white}
      hasSvgHexes={!removeBottomPadding && hasHexes}
      innerStyle={customInnerStyle(removeBottomPadding, includeHeroPadding)}
    >
      <Heading
        kicker={kicker}
        heading={heading}
        subhead={subhead}
        alignment={alignment}
        buttons={buttons}
        content={content}
      />
    </Section>
  );
};

export default HeadingComponent;
